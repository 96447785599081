<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col class="grow"> Filters </v-col>
          <v-col class="shrink">
            <v-btn @click="close()" dark icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row
          v-if="$route.name == 'CompanyTrainingRecords' || isSupervisor"
          dense
        >
          <v-col cols="12"> User </v-col>
          <v-col>
            <CustomizedAutoComplete
              :items="users"
              :value="thisFilters.userIds"
              @change="thisFilters.userIds = $event"
              clearable
              deletable-chips
              hide-details
              item-text="fullname"
              label="Select user(s)"
              multiple
            />
          </v-col>
        </v-row>
        <v-row v-if="$route.name == 'TrainingRecords'" dense>
          <v-col cols="12"> Group </v-col>
          <v-col>
            <CustomizedAutoComplete
              :items="labels"
              :value="thisFilters.labelIds"
              @change="thisFilters.labelIds = $event"
              clearable
              deletable-chips
              hide-details
              label="Select group(s)"
              multiple
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> File Name </v-col>
          <v-col>
            <v-text-field
              v-model="thisFilters.filename"
              clearable
              dense
              hide-details
              outlined
              placeholder="Enter file name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> Folder </v-col>
          <v-col cols="12">
            <CustomizedAutoComplete
              :items="groups"
              :value="thisFilters.groupIds"
              @change="thisFilters.groupIds = $event"
              clearable
              deletable-chips
              hide-details
              label="Select folder"
              multiple
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12"> Completed Date </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="createdFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.completedFrom"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  dense
                  hide-details
                  label="From"
                  outlined
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.completedFrom"
                @input="createdFromMenu = false"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="createdToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.completedTo"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  dense
                  hide-details
                  label="To"
                  outlined
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.completedTo"
                :min="completedToMin"
                @input="createdToMenu = false"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12"> Recertification Date </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="expiredFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.recertificationFrom"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  dense
                  hide-details
                  label="From"
                  outlined
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.recertificationFrom"
                @input="expiredFromMenu = false"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="expiredToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.recertificationTo"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  dense
                  hide-details
                  label="To"
                  outlined
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.recertificationTo"
                :min="recertificationToMin"
                @input="expiredToMenu = false"
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="$route.name == 'CompanyTrainingRecords'" dense>
          <v-col cols="12"> Published </v-col>
          <v-col>
            <v-btn-toggle
              v-model="thisFilters.publish"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="2" style="width: 33%" class="text-capitalize">
                Both
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :style="{ width: '33%' }" :value="0">
                    <v-icon color="orange"> mdi-eye-off </v-icon>
                  </v-btn>
                </template>
                <span>Unpublish</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :style="{ width: '33%' }" :value="1">
                    <v-icon color="green"> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>Publish</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row v-if="$route.name == 'CompanyTrainingRecords'" dense>
          <v-col cols="12"> Has Attached Users </v-col>
          <v-col>
            <v-btn-toggle
              v-model="thisFilters.hasEmployeeAttached"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="2" class="text-capitalize" style="width: 33%">
                Both
              </v-btn>
              <v-btn :value="1" class="text-capitalize" style="width: 33%">
                Yes
              </v-btn>
              <v-btn :value="0" class="text-capitalize" style="width: 34%">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row v-if="$route.name == 'CompanyTrainingRecords'" dense>
          <v-col cols="12"> User Status </v-col>
          <v-col>
            <v-btn-toggle
              v-model="thisFilters.employeeStatus"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="2" class="text-capitalize" style="width: 33%">
                Both
              </v-btn>
              <v-btn :value="1" class="text-capitalize" style="width: 33%">
                Active
              </v-btn>
              <v-btn :value="0" class="text-capitalize" style="width: 34%">
                Inactive
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row v-if="$route.name == 'CompanyTrainingRecords'" dense>
          <v-col cols="12"> Show Deleted Documents </v-col>
          <v-col>
            <v-btn-toggle
              v-model="thisFilters.showDeleted"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="2" class="text-capitalize" style="width: 34%">
                All
              </v-btn>
              <v-btn :value="1" class="text-capitalize" style="width: 33%">
                Not deleted
              </v-btn>
              <v-btn :value="0" class="text-capitalize" style="width: 33%">
                deleted
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="clear()" color="red" text> clear </v-btn>
        <v-btn @click="apply()" class="msaBlue white--text"> apply </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TrainingRecordLibraryFilter',
  props: {
    groups: {
      type: Array,
    },
    filters: {
      type: Object,
    },
    isSupervisor: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: JSON.parse(
        JSON.stringify({ ...this.$constants.TRAINING_RECORD_LIBRARY_FILTERS }),
      ),
      createdFromMenu: false,
      createdToMenu: false,
      expiredFromMenu: false,
      expiredToMenu: false,
      users: [],
      labels: [],
    };
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.$constants.TRAINING_RECORD_LIBRARY_FILTERS) !=
        JSON.stringify(this.filters)
      );
    },
    completedToMin() {
      return this.thisFilters.completedFrom == '' ||
        this.thisFilters.completedFrom == null
        ? undefined
        : this.thisFilters.completedFrom;
    },
    recertificationToMin() {
      return this.thisFilters.recertificationFrom == '' ||
        this.thisFilters.recertificationFrom == null
        ? undefined
        : this.thisFilters.recertificationFrom;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init();
      }
    },
  },
  methods: {
    clear() {
      this.thisFilters = JSON.parse(
        JSON.stringify(this.$constants.TRAINING_RECORD_LIBRARY_FILTERS),
      );
      this.apply();
    },
    apply() {
      this.fixNulls();
      this.thisFilters.groupIds.sort();
      this.$emit('applyFilters', this.thisFilters);
      this.dialog = false;
    },
    fixNulls() {
      if (this.thisFilters.completedFrom == null) {
        this.thisFilters.completedFrom = '';
      }

      if (this.thisFilters.recertificationFrom == null) {
        this.thisFilters.recertificationFrom = '';
      }

      if (this.thisFilters.completedTo == null) {
        this.thisFilters.completedTo = '';
      }

      if (this.thisFilters.recertificationTo == null) {
        this.thisFilters.recertificationTo = '';
      }

      if (this.thisFilters.filename == null) {
        this.thisFilters.filename = '';
      }
    },
    close() {
      this.dialog = false;
    },
    init() {
      this.thisFilters = JSON.parse(JSON.stringify(this.filters));
      this.loadData();
    },

    loadData() {
      const params = {
        loaderText: 'Loading...',
        userId: this.$store.getters.user.id,
      };

      const url = 'get-data-for-training-records-filter?format=json';

      this.$axios.post(url, params).then((response) => {
        this.users = response.data.users;
        this.labels = response.data.labels;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
